import React, { ReactNode } from "react";
import { GrFormClose } from "react-icons/gr";

type Props = {
  open: boolean;
  close?: () => void;
  canClose?: boolean;
  children: ReactNode;
};

const ModalContainer = ({ open, close, canClose = true, children }: Props) => {
  return (
    <div
      className={`z-30 ${
        open ? "flex" : "flex -translate-y-full"
      } transition-ease fixed inset-x-0 -top-10 bottom-0 w-full items-center justify-center overflow-auto bg-black/60`}
    >
      <section
        className={`relative z-20 flex flex-col space-y-3 overflow-hidden rounded-lg bg-white px-2 py-5`}
      >
        {canClose && (
          <div
            onClick={close}
            className="absolute text-black right-5 top-5 block hover:cursor-pointer"
          >
            <GrFormClose className="h-6 w-6" />
          </div>
        )}

        {children}
      </section>
    </div>
  );
};

export default ModalContainer;
