import { AxiosError } from "axios";
import axiosInstance from "src/config/axios";
import useSWR from "swr";
import { Bank, TransactionsResponse } from "./wallet.type";
import useSWRMutation from "swr/mutation";

export const useTransactionsQuery = (page: number, pageSize: number) => {
  const { data, error, isLoading, mutate } = useSWR<
    any,
    AxiosError,
    [string, string]
  >(
    ["/all/getAllMyTransactions", `?page=${page + 1}&limit=${pageSize}`],
    ([url, queryParams]) =>
      axiosInstance.get(url + queryParams).then((res) => res.data.data)
  );

  return {
    transactions: data,
    isLoading,
    error,
    getTransactions: mutate,
  };
};

export const useTransactionsDetailsQuery = (id: string) => {
  const { data, error, isLoading, mutate } = useSWR<any, AxiosError, string>(
    `/all/getTransactionDetail/${id}`,
    (url: string) => axiosInstance.get(url).then((res) => res.data?.data)
  );

  return {
    transaction: data,
    isLoading,
    error,
    getTransaction: mutate,
  };
};

export const useBanksQuery = () => {
  const { data, error, isLoading, mutate } = useSWR<
    Bank[],
    AxiosError,
    `/all/getAllBanks`
  >(`/all/getAllBanks`, (url: string) =>
    axiosInstance.get(url).then((res) => res.data?.data)
  );

  return {
    banks: data,
    isLoading,
    error,
    getBanks: mutate,
  };
};

export const useWithdrawMutation = () => {
  const { data, trigger, isMutating, error } = useSWRMutation<
    any,
    AxiosError,
    string,
    any,
    any
  >(`/dev/withdrawFunds`, (url: string, { arg }) =>
    axiosInstance.post(url, arg).then((res) => res.data)
  );

  return {
    data,
    isLoading: isMutating,
    withdraw: trigger,
  };
};

export const useValidateBankAccountMutation = () => {
  const { data, trigger, isMutating, error } = useSWRMutation<
    any,
    AxiosError,
    string,
    any,
    any
  >(`/dev/validateBankAccount`, (url: string, { arg }) =>
    axiosInstance.post(url, arg).then((res) => res.data)
  );

  return {
    data,
    isLoading: isMutating,
    validate: trigger,
  };
}; 

export const useConvertCashbackMutation = () => {
  const { data, trigger, isMutating, error } = useSWRMutation<
    any,
    AxiosError,
    string,
    any,
    any
  >(`/all/convertCashback`, (url: string, { arg }) =>
    axiosInstance.put(url, arg).then((res) => res.data)
  );

  return {
    data,
    isLoading: isMutating,
    convertCashback: trigger,
  };
}; 
