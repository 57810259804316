import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Table from '../../components/Table/Table';
import { useTransactionsQuery } from 'src/lib/endpoints/wallet/wallet.api';
import useAlert from "src/hooks/use-alert";
import Pagination from "src/components/Pagination";
import Status from 'src/components/Status/Status';
import { format } from 'date-fns';
import { commaSeperatedNumberFormat } from 'src/lib/utils/format-text';
import { useLocation } from 'react-router-dom';

const TransactionTable = () => {
  const siblingCount = 1;
  const pageColumn = 1;
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(1000);
  const { transactions } = useTransactionsQuery(page, pageSize)
  const { state, open, close, Alert } = useAlert();
  const location = useLocation();

  const handlePageChange = useCallback(
    (_page) => {
      setPage(_page - 1);
    },
    [page]
  );

  const tableHeader = useMemo(
    () => [
      {
        title: 'ID',
        key: 'id',
      },
      {
        title: 'Txn ID',
        key: 'txn_id',
      },
      {
        title: 'Type',
        key: 'type',
      },
      {
        title: 'Amount',
        key: 'amount',
      },
      {
        title: 'Date',
        key: 'date',
      },
      {
        title: 'Status',
        key: 'status',
      },
    ],
    []
  );

  const modifiedData = useMemo(() => {
    return transactions?.data.map((each) => [
      <p className='capitalize'>
        # {each?.id}
      </p>,
      <p className='capitalize'>
        {each?.transfer_id ?? '-'}
      </p>,
      <p className='capitalize'>
        {each.type}
      </p>,
      <p>${commaSeperatedNumberFormat(each.amount)}</p>,
      format(new Date(each?.created_at ?? Date.now()), "EEE, MMM d"),
      <Status
        label={
          each?.status.toLowerCase()
        }
      />,
    ]);
  }, [transactions]);

  useEffect(() => {
    setTotalCount(transactions?.total ?? 0)
  }, [transactions])

  return (
    <div className={`${location.pathname === '/transactions' && 'pt-10'}`}>
      {!!modifiedData?.length && <Table
        header={tableHeader}
        label='Transactions'
        column={modifiedData}
        defaultDisplay={<div>Engage with droomwork services to see your list of transactions</div>}
      />}

      <section className="w-full py-5">
        <Pagination
          rangeStart={
            totalCount !== 0
              ? (page || 0) * ((pageSize || 0) * pageColumn) + 1
              : 0
          }
          totalCount={totalCount}
          currentPage={(page || 0) + 1}
          pageSize={pageSize}
          pageColumn={pageColumn}
          siblingCount={siblingCount}
          onPageChange={handlePageChange}
          className="pt-9"
        />
      </section>

      <span className="fixed right-3 top-20 z-30">
        <Alert />
      </span>
    </div>
  );
};

export default TransactionTable;
