import { useState } from "react";
import useAlert from "src/hooks/use-alert";
import useStateHook from "src/hooks/useStateHook";
import { useUpdateProfileMutation } from "src/lib/endpoints/profile/profile.api";
import { useFilePicker } from "use-file-picker";
import Button from "../Button";
import OutlineButton from "../Button/OutlineButton";
import Heading from "./Heading";

type Props = {
  data?: string;
  callback?: () => void;
  close?: () => void;
};

const EditCv = ({ data, callback, close }: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { state, open: openAlert, close: closeAlert, Alert } = useAlert();
  const { updateProfile } = useUpdateProfileMutation();
  const { handleStateUpdate } = useStateHook();
  const {
    openFilePicker,
    loading: fileLoading,
    plainFiles,
    filesContent,
  } = useFilePicker({
    readAs: "DataURL",
    accept: [".pdf", ".doc", ".docx"],
    multiple: false,
    limitFilesConfig: { min: 1 },
    minFileSize: 0.1, // in megabytes
    maxFileSize: 1, // in megabytes
  });

  const submit = async () => {
    if (isSubmitting) return;
    if (plainFiles.length === 0) return;
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append("cv", plainFiles[0]);

    await updateProfile(formData, {
      onSuccess: async (res) => {
        setIsSubmitting(false);
        openAlert({
          ...state,
          title: "Cv updated",
          variant: "success",
          active: true,
        });
        handleStateUpdate(res.data);
        callback && callback();
        close && close();
      },
      onError: (error: any) => {
        setIsSubmitting(false);
        openAlert({
          ...state,
          title: error?.response?.data?.message ?? error?.message ?? "",
          variant: "error",
          active: true,
        });
      },
    });
  };

  return (
    <div className="flex flex-col space-y-4 w-full">
      <Heading title="CV upload" />

      <section className="flex flex-col space-y-5 w-full">
        <div className="">
          <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-600 border-dashed rounded-md">
            <div className="space-y-1 text-center">
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div className="flex text-sm text-gray-600">
                <label
                  onClick={openFilePicker}
                  htmlFor="file-upload"
                  className="relative cursor-pointer bg-white rounded-md font-medium text-[#f49038] hover:text-[#f49038] focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-[#f49038]"
                >
                  <span>Upload CV</span>
                  {/* <input
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    className="sr-only"
                    onChange={handleJDChange}
                  /> */}
                </label>
                <p className="pl-1">or drag and drop</p>
              </div>
              <p className="text-xs text-gray-500">.pdf only less than 1MB</p>
            </div>
          </div>

          {filesContent.length > 0 && (
            <p className="font-semibold text-black text-center">
              <span>{filesContent[0].name}</span>
            </p>
          )}
        </div>

        <div className="flex justify-between lg:justify-end lg:gap-6 items-center w-full pb-20">
          <OutlineButton
            label={"Cancel"}
            className={"border border-primary text-primary"}
            onClick={callback}
          />
          <Button
            type={"submit"}
            label={"Update"}
            loading={isSubmitting}
            className={"bg-primary text-white border border-transparent"}
            onClick={submit}
          />
        </div>
      </section>

      <span className="fixed right-3 top-3 z-30">
        <Alert />
      </span>
    </div>
  );
};

export default EditCv;
