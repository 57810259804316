import { useContext, useState, useEffect } from 'react';
import { FiMenu, FiSearch } from 'react-icons/fi';
import { IoMdNotifications } from 'react-icons/io';
import Logo from '../../assets/images/logo.png';
import { ThemeContext } from '../../context/ThemeContext';
import Button from '../Button';
import ToggleSwitch from '../Inputs/ToggleSwitch';
import ReferralModal from '../Modal/ReferralModal';
import MobileMenu from './MobileMenu';
import UserInfo from './UserInfo';
import { useUserNotificationsQuery } from 'src/lib/endpoints/dashboard/dashboard.api';
import Notification from '../Notification/Notification';
import AnnouncementBanner from '../AnnouncementBanner/AnnouncementBanner';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showReferralModal, setShowReferralModal] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const { theme } = useContext(ThemeContext);
  const { notifications } = useUserNotificationsQuery()
  const [mounted, setMounted] = useState(false);

  const handleReferral = () => {
    setShowReferralModal(true);
  };
  const closeModal = () => {
    setShowReferralModal(false);
  };

  useEffect(() => {
    if (!mounted) {
      setTimeout(() => {
        setMounted(true)
      }, 1000);
    };
  }, []);

  return (
    <div className='relative'>
      <div className=' fixed top-0 left-0 w-full z-10'>
        <div
          className={`${theme === 'dark'
            ? 'bg-black shadow-header-dark'
            : 'bg-white shadow-header'
            } flex justify-between items-center py-[26px] px-[18px] sm:px-8  md:px-12 lg:px-[60px] z-[1035px]`}
        >
          {/* MENU AND LOGO  */}
          <div className='flex items-center gap-7'>
            <div className='lg:hidden'>
              {/* MOBILE HAMBURGER MENU ICON */}
              <FiMenu size={40} onClick={() => setShowMobileMenu((o) => !o)} />
            </div>
            <div className='w-[50px] h-[50px]'>
              <img src={Logo} alt='droomwork-logo' />
            </div>
          </div>
          {/* SEARCH */}
          <div className='flex items-center gap-10 lg:hidden'>
            <FiSearch size={24} />
            <div className='relative hover:cursor-pointer'
              onClick={() => setShowNotification((o) => !o)}
            >
              <IoMdNotifications
                size={32}
              />

              {!!notifications?.notReadCount && <p className='absolute top-0 right-0 bg-red-500 text-white text-xs p-0.5 h-fit rounded-full'>
                {notifications?.notReadCount > 99 ? '99+' : notifications?.notReadCount}
              </p>}
            </div>
          </div>
          {/* LARGER SCREEN BUTTONS */}
          <div className='relative hidden lg:flex items-center gap-7'>
            {/* <ToggleSwitch /> */}
            <Button
              label={'Refer & Earn'}
              className={'bg-primary text-white'}
              onClick={handleReferral}
            />
            <ReferralModal open={showReferralModal} closeModal={closeModal} />
            <div className='inline-block h-[42px] min-h-[1em] w-0.5 self-stretch bg-neutral-100 opacity-100 dark:opacity-50 m-auto'></div>
            <div className='flex items-center gap-10'>
              <div className='relative hover:cursor-pointer'
                onClick={() => setShowNotification((o) => !o)}
              >
                <IoMdNotifications
                  size={32}
                />

                {!!notifications?.notReadCount && <p className='absolute top-0 right-0 bg-red-500 text-white text-xs p-0.5 h-fit rounded-full'>
                  {notifications?.notReadCount > 99 ? '99+' : notifications?.notReadCount}
                </p>}
              </div>

              <Link to='/profile'>
                <UserInfo />
              </Link>
            </div>
          </div>
        </div>

        {mounted && <AnnouncementBanner />}

        {showNotification && <Notification open={showNotification} callback={() => setShowNotification(false)} />}
        {/* Mobile Menu */}
        <div className=' '>
          <MobileMenu
            showMobileMenu={showMobileMenu}
            setShowMobileMenu={setShowMobileMenu}
          />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
