import { useState } from "react";
import useAlert from "src/hooks/use-alert";
import useStateHook from "src/hooks/useStateHook";
import { useUpdateProfileMutation } from "src/lib/endpoints/profile/profile.api";
import { selectUserState } from "src/lib/features/auth/authSlice";
import { useTypedSelector } from "src/lib/store/store";
import { useFilePicker } from "use-file-picker";
import Button from "../Button";
import EditCv from "../EditProfile/EditCv";
import Heading from "../EditProfile/Heading";
import { ScrollArea } from "../ui/scroll-area";
import ModalContainer from "./ModalContainer";

type Props = {
  open: boolean;
  close: () => void;
};

const CompleteProfileUpdate = ({ open, close }: Props) => {
  const { user } = useTypedSelector(selectUserState);
  const [toggleCV, setToggleCV] = useState(!!user?.photo_public_id);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { state, open: openAlert, close: closeAlert, Alert } = useAlert();
  const { updateProfile } = useUpdateProfileMutation();
  const { handleStateUpdate } = useStateHook();
  const {
    openFilePicker,
    loading: fileLoading,
    plainFiles,
    filesContent,
  } = useFilePicker({
    readAs: "DataURL",
    accept: "image/*",
    multiple: false,
    limitFilesConfig: { min: 1 },
    minFileSize: 0.1, // in megabytes
    maxFileSize: 1, // in megabytes
  });

  const submit = async () => {
    if (isSubmitting) return;
    if (plainFiles.length === 0) return;

    setIsSubmitting(true);

    const formData = new FormData();
    formData.append("photo", plainFiles[0]);

    await updateProfile(formData, {
      onSuccess: async (res: any) => {
        setIsSubmitting(false);
        openAlert({
          ...state,
          title: "Profile photo updated",
          variant: "success",
          active: true,
        });
        handleStateUpdate(res.data);
        setToggleCV(true);
      },
      onError: (error: any) => {
        setIsSubmitting(false);
        openAlert({
          ...state,
          title: error?.response?.data?.message ?? error?.message ?? "",
          variant: "error",
          active: true,
        });
      },
    });
  };

  //   useEffect(() => {
  //     document.addEventListener(
  //       "keydown",
  //       (e) => e.key === "Escape" && close && close()
  //     );

  //     return () => {
  //       document.removeEventListener("keydown", (e) => e.key === "Escape");
  //     };
  //   }, []);

  return (
    <div className="w-full">
      <ModalContainer open={open} canClose={false}>
        <ScrollArea className="p-3 w-[90vw] h-[40vh] md:h-[55vh] md:w-[40vw]">
          <div className="flex flex-col w-full md:flex-row md:items-center space-y-4 md:space-y-0 md:space-x-4">
            {!toggleCV ? (
              <div className="flex flex-col space-y-4 w-full">
                <Heading title="Profile Picture Upload" />

                <section className="flex flex-col space-y-5 w-full">
                  <div className="">
                    <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-600 border-dashed rounded-md">
                      <div className="space-y-1 text-center">
                        <svg
                          className="mx-auto h-12 w-12 text-gray-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <div className="flex text-sm text-gray-600">
                          <label
                            onClick={openFilePicker}
                            htmlFor="file-upload"
                            className="relative cursor-pointer bg-white rounded-md font-medium text-[#f49038] hover:text-[#f49038] focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-[#f49038]"
                          >
                            <span>Upload an image</span>
                          </label>
                          <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs text-gray-500">
                          .image only less than 1MB
                        </p>
                      </div>
                    </div>

                    {filesContent.length > 0 && (
                      <p className="font-semibold text-black text-center">
                        <span>{filesContent[0].name}</span>
                      </p>
                    )}
                  </div>

                  <div className="flex justify-between md::justify-end lg:gap-6 items-center w-full">
                    {/* <OutlineButton
                      label={"Cancel"}
                      className={"border border-primary text-primary"}
                    //   onClick={callback}
                    /> */}
                    <Button
                      type={"submit"}
                      label={"Update"}
                      loading={isSubmitting}
                      className={
                        "bg-primary text-white border border-transparent"
                      }
                      onClick={submit}
                    />
                  </div>
                </section>

                <span className="fixed right-3 top-3 z-30">
                  <Alert />
                </span>
              </div>
            ) : (
              <EditCv callback={() => setToggleCV(false)} close={close} />
            )}
          </div>
        </ScrollArea>
      </ModalContainer>
    </div>
  );
};

export default CompleteProfileUpdate;
