const Status = ({ label }) => {
  return (
    <div
      className={`inline-flex p-[15px] py-[2px] text-xs text-center capitalize rounded-[15px] text-white ${label.toLowerCase() === 'active' ||
          label.toLowerCase() === 'open' ||
          label.toLowerCase() === 'finished' ||
          label.toLowerCase() === 'completed' ||
          label.toLowerCase() === 'paid' ||
          label.toLowerCase() === 'accepted' ||
          label.toLowerCase() === 'successful' ||
          label.toLowerCase() === 'successfull'
          ? 'bg-[#7AB9FF] text-[#FFF]'
          : label.toLowerCase() === 'closed' ||
            label.toLowerCase() === 'on-going' ||
            label.toLowerCase() === 'pending' ||
            label.toLowerCase() === 'unpaid'
            ? 'bg-[#D89C51]'
            : label.toLowerCase() === 'offered'
              ? 'bg-[#001935]'
              : label.toLowerCase() === 'not verified'
                ? 'bg-[#EDDAC1] text-[0.5rem]'
                : label.toLowerCase() === 'to-start'
                  ? 'bg-[#FF543E] text-white'
                  : 'bg-[#FF543E] font-bold'
        }`}
    >
      {label}
    </div>
  );
};

export default Status;
