import { useEffect } from "react";
import { GOOGLE_ANALYTICS } from "src/utils/constants";

const GoogleAnalytics = () => {
    useEffect(() => {
        // Google Tag Manager script
        const googleTagScript = document.createElement('script');
        googleTagScript.id = "google_tag";
        googleTagScript.src = "https://www.googletagmanager.com/gtag/js?id=G-J4W91HKYYX";
        googleTagScript.async = true;
        document.body.appendChild(googleTagScript);

        // Google Analytics initialization
        const googleAnalyticsScript = document.createElement("script");
        googleAnalyticsScript.id = "google_analytics";
        googleAnalyticsScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-J4W91HKYYX', {
        page_path: window.location.pathname,
      });
    `;
        document.head.appendChild(googleAnalyticsScript);

        // Cleanup function to remove the script on component unmount
        return () => {
            document.getElementById("google_analytics")?.remove();
            document.getElementById("google_tag")?.remove();
        };
    }, []);

    return null
    // return (
    //     <>
    //         <script id="google_tag" async src="https://www.googletagmanager.com/gtag/js?id=G-J4W91HKYYX"></script>
    //         <script id="google_analytics">
    //             {`
    //             window.dataLayer = window.dataLayer || [];
    //             function gtag(){dataLayer.push(arguments);}
    //             gtag('js', new Date());
    //             gtag('config', '${GOOGLE_ANALYTICS}', {
    //             page_path: window.location.pathname,
    //             });
    //         `}
    //         </script>
    //     </>
    // );
};

export default GoogleAnalytics;
