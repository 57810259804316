import { useEffect } from "react";
import { HOTJAR_ID } from "../../utils/constants";

const HotJar = () => {
    // useEffect(() => {
    //     const script = document.createElement("script");
    //     script.id = "hotjar";
    //     script.async = true;
    //     script.innerHTML = `
    //   (function(h,o,t,j,a,r){
    //         h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
    //         h._hjSettings={hjid:${HOTJAR_ID},hjsv:6};
    //         a=o.getElementsByTagName('head')[0];
    //         r=o.createElement('script');r.async=1;
    //         r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
    //         a.appendChild(r);
    //     })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    // `;
    //     document.head.appendChild(script);

    //     // Cleanup function to remove the script on component unmount
    //     return () => {
    //         document.getElementById("hotjar")?.remove();
    //     };
    // }, []);

    return null

    // return (
    //     <script id="hotjar">
    //         {`
    //             (function(h,o,t,j,a,r){
    //                 h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
    //                 h._hjSettings={hjid:${HOTJAR_ID},hjsv:6};
    //                 a=o.getElementsByTagName('head')[0];
    //                 r=o.createElement('script');r.async=1;
    //                 r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
    //                 a.appendChild(r);
    //             })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    //         `}
    //     </script>
    // );
};

export default HotJar;
