import { format } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import PageIntro from "src/components/PageIntro/PageIntro";
import Pagination from "src/components/Pagination";
import Status from "src/components/Status/Status";
import Table from "src/components/Table/Table";
import useAlert from "src/hooks/use-alert";
import { useActiveProjectQuery } from "src/lib/endpoints/job/job.api";
import { useWorkHistoryQuery } from "src/lib/endpoints/profile/profile.api";

const ActiveProjects = () => {
  const { pathname } = useLocation();
  const siblingCount = 1;
  const pageColumn = 1;
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const { workHistory } = useWorkHistoryQuery(
    page + 1,
    searchParams.get("status") ?? "on-going"
  );
  const [totalCount, setTotalCount] = useState(0);
  const { state, open, close, Alert } = useAlert();

  const handlePageChange = useCallback(
    (_page: number) => {
      setPage(_page - 1);
    },
    [page]
  );
  
  const tabHeading = useMemo(
    () => [
      {
        id: 1,
        title: "on-going",
      },
      {
        id: 2,
        title: "finished",
      },
    ],
    []
  );

  const tableHeader = useMemo(
    () => [
      {
        title: "Client",
        key: "client",
      },
      {
        title: "Project",
        key: "project",
      },
      {
        title: "Date",
        key: "date",
      },
      {
        title: "Status",
        key: "status",
      },
      {
        title: "",
        key: "details",
      },
    ],
    []
  );

  const modifiedData = useMemo(() => {
    return workHistory?.data?.map((each) => [
      <div className="flex gap-[18px] items-center">
        <div className="relative w-11 h-11 rounded-full overflow-hidden">
          <img
            src={each?.projectDetailJobDetail?.companyLogo ?? ""}
            alt={each?.projectDetailJobDetail?.companyName ?? ""}
            className="rounded-full"
          />
        </div>
        <p className="capitalize">
          {each?.projectDetailJobDetail?.companyName ?? ""}
        </p>
      </div>,
      <p className="capitalize">
        {each?.projectDetailJobDetail?.jobTitle ?? ""}
      </p>,
      <p>{format(each?.startDate ?? new Date().getTime(), "EEE, MMM d")}</p>,
      <Status label={each?.status} />,
      <Link
        className="inline-block rounded-md py-[2px] px-3 border border-[#575555] text-[#8D8989]"
        to={`/project/${each.job_detail_id}`}
      >
        Details
      </Link>,
    ]);
  }, [workHistory]);

  useEffect(() => {
    setTotalCount(workHistory?.total ?? 0);
  }, [workHistory]);
  
  useEffect(() => {
    if (!searchParams.get("status")) {
      setSearchParams({ status: "on-going" });
    }
  }, []);

  if (!modifiedData) return null;

  return (
    <div>
      {!pathname.includes("projects") ? (
        <div className="flex justify-end">
          <Link
            to={"/projects"}
            className="underline underline-offset-2 font-medium my-2 w-fit"
          >
            See all
          </Link>
        </div>
      ) : (
        <>
          <h1 className="text-sm font-bold leading-8 mb-20 md:mb-6 text-left">
            {`Dashboard > Projects`}
          </h1>

          <div className="mb-10 lg:mb-12">
            <PageIntro title={"Projects"} description={""} />
          </div>
        </>
      )}

      <section className="flex w-fit items-center space-x-5 pb-5">
        {tabHeading.map((each, i) => (
          <section
            key={each.id}
            onClick={() => setSearchParams({ status: each.title })}
            className={`mx-auto capitalize flex w-fit text-xl items-center justify-center space-x-2 hover:cursor-pointer ${
              searchParams.get("status") === each.title &&
              "border-b-2 border-b-black"
            }`}
          >
            <p
              className={`text-base lg:text-lg ${
                searchParams.get("status") === each.title && "text-primary-6"
              } w-fit text-center font-semibold`}
            >
              {each.title}
            </p>
          </section>
        ))}
      </section>

      <Table
        header={tableHeader}
        label="Project History"
        column={modifiedData}
        defaultDisplay={
          <div>You have no {searchParams.get("status")} work history yet</div>
        }
      />

      <section className="w-full py-5">
        <Pagination
          rangeStart={
            totalCount !== 0
              ? (page || 0) * ((pageSize || 0) * pageColumn) + 1
              : 0
          }
          totalCount={totalCount}
          currentPage={(page || 0) + 1}
          pageSize={pageSize}
          pageColumn={pageColumn}
          siblingCount={siblingCount}
          onPageChange={handlePageChange}
          className="pt-9"
        />
      </section>

      <span className="fixed right-3 top-3 z-30">
        <Alert />
      </span>
    </div>
  );
};

export default ActiveProjects;
