import { useCallback, useEffect, useMemo, useState } from "react";
import { IoEllipseSharp } from "react-icons/io5";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import PageIntro from "src/components/PageIntro/PageIntro";
import Pagination from "src/components/Pagination";
import Status from "src/components/Status/Status";
import useAlert from "src/hooks/use-alert";
import { useMatchedJobsQuery } from "src/lib/endpoints/job/job.api";
import Table from "../../components/Table/Table";
import { format } from "date-fns";

const MatchedJobsTable = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const siblingCount = 1;
  const pageColumn = 1;
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const { matchedJobs, isLoading } = useMatchedJobsQuery(
    page + 1,
    searchParams.get("status") ?? "open"
  );
  const [totalCount, setTotalCount] = useState(0);
  const { state, open, close, Alert } = useAlert();

  const handlePageChange = useCallback(
    (_page: number) => {
      setPage(_page - 1);
    },
    [page]
  );
  
  const tableHeader = useMemo(
    () => [
      {
        title: "Client",
        key: "client",
      },
      {
        title: "Request",
        key: "request",
      },
      {
        title: "Date",
        key: "date",
      },
      {
        title: "Status",
        key: "status",
      },
      {
        title: "",
        key: "details",
      },
    ],
    []
  );

  const tabHeading = useMemo(
    () => [
      {
        id: 1,
        title: "open",
      },
      {
        id: 2,
        title: "offered",
      },
      {
        id: 3,
        title: "closed",
      },
    ],
    []
  );

  const modifiedData = useMemo(() => {
    return matchedJobs?.data.map((each: any) => [
      <div className="flex gap-[18px] items-center">
        <div className="relative w-11 h-11 rounded-full overflow-hidden">
          <img
            src={each?.companyLogo ?? ""}
            alt={each?.companyName ?? ""}
            className="rounded-full"
          />
          <div className="absolute -right-1 bottom-1">
            <IoEllipseSharp fill="#1FAF2D" color="#1FAF2D" size={10} />
          </div>
        </div>
        <p>{each?.companyName ?? ""}</p>
      </div>,
      <p className="capitalize">{each?.jobTitle ?? ""}</p>,
      <p>{format(new Date(each?.created_at ?? Date.now()), "EEE, MMM d")}</p>,
      <Status label={each.status} />,
      <div
        className="inline-block rounded-md py-[2px] hover:cursor-pointer px-3 border border-[#575555] text-[#8D8989]"
        onClick={() => {
          navigate(`/jobs/${each?.id ?? ""}`);
        }}
      >
        Details
      </div>,
    ]);
  }, [matchedJobs]);

  useEffect(() => {
    setTotalCount(matchedJobs?.total ?? 0);
  }, [matchedJobs]);

  useEffect(() => {
    if (!searchParams.get("status")) {
      setSearchParams({ status: "open" });
    }
  }, []);

  if (isLoading || matchedJobs === undefined || modifiedData === undefined)
    return null;

  
  // return null

  return (
    <div>
      {!pathname.includes("job") ? (
        <div className="flex justify-end">
          <Link
            to={"/jobs"}
            className="underline underline-offset-2 font-medium my-2 w-fit"
          >
            See all
          </Link>
        </div>
      ) : (
        <>
          <h1 className="text-sm font-bold leading-8 mb-20 md:mb-6 text-left">
            {`Dashboard > All Matched Job`}
          </h1>

          <div className="mb-10 lg:mb-12">
            <PageIntro title={"Matched Jobs"} description={""} />
          </div>

          <section className="flex w-fit items-center space-x-5 pb-5">
            {tabHeading.map((each, i) => (
              <section
                key={each.id}
                onClick={() => {
                  setSearchParams({ status: each.title });
                }}
                className={`mx-auto capitalize flex w-fit text-xl items-center justify-center space-x-2 hover:cursor-pointer ${
                  searchParams.get("status") === each.title &&
                  "border-b-2 border-b-black"
                }`}
              >
                <p
                  className={`text-base lg:text-lg ${
                    searchParams.get("status") === each.title &&
                    "text-primary-6"
                  } w-fit text-center font-semibold`}
                >
                  {each.title}
                </p>
              </section>
            ))}
          </section>
        </>
      )}
      <Table
        header={tableHeader}
        label="Matched Jobs"
        column={modifiedData}
        defaultDisplay={
          <div>You have no {searchParams.get("status")} matched job yet</div>
        }
      />

      <section className="w-full py-5">
        <Pagination
          rangeStart={
            totalCount !== 0
              ? (page || 0) * ((pageSize || 0) * pageColumn) + 1
              : 0
          }
          totalCount={totalCount}
          currentPage={(page || 0) + 1}
          pageSize={pageSize}
          pageColumn={pageColumn}
          siblingCount={siblingCount}
          onPageChange={handlePageChange}
          className="pt-9"
        />
      </section>

      <span className="fixed right-3 top-3 z-30">
        <Alert />
      </span>
    </div>
  );
};

export default MatchedJobsTable;
