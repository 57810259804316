import { formatDistanceToNow } from "date-fns";
import { useState } from "react";
import Divider from '../Divider';
import { useUserReadNotificationsMutation } from "src/lib/endpoints/dashboard/dashboard.api";

const NotificationCard = ({ notification }) => {
  const [isRead, setIsRead] = useState(!!notification?.is_read)
  const [toggle, setToggle] = useState(false)
  const { readNotification } = useUserReadNotificationsMutation(notification?.id);


  const markAsRead = async () => {
    setIsRead(true)
    readNotification(
      {},
      {
        onSuccess: () => { },
        onError: () => { },
      }
    );
  };

  return (
    <div>
      <div
        onClick={() => {
          markAsRead();
          setToggle(prev => !prev)
        }}
        className={`flex space-x-5 py-3 hover:cursor-pointer ${isRead ? "text-gray-400" : "text-primary"
          }`}
      >
        <div className="w-12 h-12 rounded-full overflow-hidden">
          <img
            src={notification?.sender_avatar ?? ""}
            className=""
            alt="Avatar"
          />
        </div>
        <div>
          <p className="font-semibold">{notification.title}</p>
          <p className="text-sm">
            {formatDistanceToNow(
              new Date(notification.created_at ?? Date.now()),
              { addSuffix: true }
            )}
          </p>
        </div>
      </div>
      {toggle && (
        <div className="text-primary">{notification.body}</div>
      )}
      <Divider className={""} />
    </div>
  );
};

export default NotificationCard;
