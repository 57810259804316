import { AxiosError } from "axios";
import useSWR from "swr";

import { DashboardParametersResponse } from "./dashboard.type";
import axiosInstance from "src/config/axios";
import useSWRMutation from "swr/mutation";

export const useDashboardParametersQuery = () => {
  const { data, error, isLoading, mutate } = useSWR<
    DashboardParametersResponse,
    AxiosError,
    `/dev/getAllMyDashboardParameters`
  >(
    `/dev/getAllMyDashboardParameters`,
    (url: string) => axiosInstance.get(url).then((res) => res.data),
    {
      refreshWhenHidden: true,
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnMount: true,
      revalidateOnReconnect: true,
    }
  );

  return {
    dashboardParameters: data,
    isLoading,
    error,
    getDashboardParameters: mutate,
  };
};

export const useUserNotificationsQuery = (page: number) => {
  const { data, error, isLoading, mutate } = useSWR<
    any,
    AxiosError,
    [string, string]
  >(
    ["/all/user-notifications", `?page=${page}`],
    ([url, queryParams]) =>
      axiosInstance.get(url + queryParams).then((res) => res.data.data),
    {
      refreshWhenHidden: true,
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnMount: true,
      revalidateOnReconnect: true,
    }
  );

  return {
    notifications: data,
    isLoading,
    error,
    getNotifications: mutate,
  };
};

export const useUserReadNotificationsMutation = (id: number) => {
  const { data, trigger, isMutating, error } = useSWRMutation<
    {},
    AxiosError,
    string,
    FormData,
    any
  >(`/all/read-notifications/${id}`, (url: string, { arg }) =>
    axiosInstance.put(url, arg).then((res) => res.data)
  );

  return {
    data,
    isLoading: isMutating,
    readNotification: trigger,
  };
};

export const useUserReadAllNotificationsMutation = () => {
  const { data, trigger, isMutating, error } = useSWRMutation<
    {},
    AxiosError,
    string,
    FormData,
    any
  >(`/all/read-notifications`, (url: string, { arg }) =>
    axiosInstance.put(url, arg).then((res) => res.data)
  );

  return {
    data,
    isLoading: isMutating,
    readNotifications: trigger,
  };
};
