import { FaChevronDown } from 'react-icons/fa';
import { selectUserState } from 'src/lib/features/auth/authSlice';
import { useTypedSelector } from 'src/lib/store/store';

const UserInfo = () => {
  const user = useTypedSelector(selectUserState).user

  return (
    <div className="flex items-center space-x-3">
      <div
        className={`relative rounded-full overflow-hidden w-14 h-14`}
      >
        <img
          src={user?.photo ?? ""}
          className="rounded-full w-full h-full"
          alt={user?.firstName + " " + user?.lastName}
        />
      </div>
      <div>
        <div className="flex items-center gap-2 font-semibold">
          <p
            className={`text-left text-lg leading-8 font-semibold text-[#8D8989]`}
          >
            {user?.firstName + " " + user?.lastName}
          </p>
          <FaChevronDown />
        </div>
        {/* <p
          className={`text-sm
           text-left text-[#8D8989] whitespace-nowrap`}
        >
          Coupon Earnings: $23
        </p> */}
      </div>
    </div>
  );
};

export default UserInfo;
