import { useContext, useState } from "react";
import { CiEdit } from "react-icons/ci";
import useAlert from "src/hooks/use-alert";
import { useUpdateProfileMutation } from "src/lib/endpoints/profile/profile.api";
import { selectUserState } from "src/lib/features/auth/authSlice";
import { useTypedSelector } from "src/lib/store/store";
import { useFilePicker } from "use-file-picker";
import { ThemeContext } from "../../context/ThemeContext";
import EditName from "../EditProfile/EditName";
import ModalContainer from "../Modal/ModalContainer";
import useStateHook from "src/hooks/useStateHook";

type Props = {
  bigSize?: boolean;
  verified?: boolean;
  edit?: boolean;
  showStatus?: boolean;
};

const AvatarCard = ({ bigSize, verified, showStatus, edit }: Props) => {
  const user = useTypedSelector(selectUserState).user;
  const [userPhoto, setUserPhoto] = useState(user?.photo ?? '')
  const { theme } = useContext(ThemeContext);
  const { state, open: openAlert, close: closeAlert, Alert } = useAlert();
  const [editSection, setEditSection] = useState("");
  const [uploading, setUploading] = useState(false);
  const { updateProfile } = useUpdateProfileMutation();
  const { handleStateUpdate } = useStateHook();
  const { openFilePicker, loading: fileLoading } = useFilePicker({
    readAs: "DataURL",
    accept: "image/*",
    multiple: false,
    limitFilesConfig: { min: 1 },
    minFileSize: 0.1, // in megabytes
    maxFileSize: 1, // in megabytes
    onFilesSuccessfullySelected: async ({ plainFiles, filesContent }) => {
      setUploading(true);

      const formData = new FormData();
      formData.append("photo", plainFiles[0]);

      await updateProfile(formData, {
        onSuccess: async (res: any) => {
          setUploading(false);
          openAlert({
            ...state,
            title: "Profile photo updated",
            variant: "success",
            active: true,
          });
          handleStateUpdate(res.data);
          setUserPhoto(res.data.photo)
        },
        onError: (error: any) => {
          setUploading(false);
          openAlert({
            ...state,
            title: error?.response?.data?.message ?? error?.message ?? "",
            variant: "error",
            active: true,
          });
        },
      });
    },
  });

  return (
    <div className="flex items-center w-full space-x-5">
      <div className="relative">
        <div
          className={`relative flex items-center justify-center rounded-full overflow-hidden ${
            bigSize
              ? "w-[60px] h-[60px] lg:w-[138px] lg:h-[138px]"
              : "w-[60px] h-[60px]"
          }`}
        >
          <img
            src={userPhoto ?? ""}
            className="rounded-full w-full h-full"
            alt="Avatar"
          />
        </div>
        {/* {user?.isVerified && (
          <BsFillPatchCheckFill
            className="absolute right-1 top-[80%]"
            size={bigSize ? 20 : 16}
            color="#F58800"
          />
        )} */}
        {edit && (
          <div className="absolute right-2 bottom-2 w-fit h-fit p-1 rounded-full border bg-white border-gray-400 hover:cursor-pointer">
            <CiEdit onClick={openFilePicker} className="h-4 w-4" />
          </div>
        )}
      </div>

      <div className="w-full">
        <p
          className={`text-left flex whitespace-pre truncate w-[70%] ${
            bigSize ? "lg:text-[1.75rem] text-lg" : "text-lg"
          } leading-8 font-semibold ${
            theme === "dark" ? "text-white" : "text-[#001935]"
          }`}
        >
          <p className="capitalize">
            {user?.firstName ?? ""} {user?.lastName ?? ""}{" "}
          </p>
          {edit && (
            <div className="w-fit h-fit p-1 rounded-full border bg-white border-gray-400 hover:cursor-pointer">
              <CiEdit
                onClick={() => setEditSection("name")}
                className="h-4 w-4"
              />
            </div>
          )}
        </p>
        <p
          className={`whitespace-pre capitalize ${
            bigSize ? "lg:text-xl text-sm" : "text-sm"
          } text-left font-semibold text-[#8D8989] whitespace-nowrap`}
        >
          {user?.extraDetail.primaryStack}
        </p>
        <p
          className={`whitespace-pre text-sm text-left font-semibold text-[#8D8989]`}
        >
          {user?.email}
        </p>
        {/* {!user?.isVerified && <Status label={"Not verified"} />} */}
      </div>

      {/* Profile edit modal */}
      <ModalContainer open={!!editSection} close={() => setEditSection("")}>
        <div className="h-[70vh] min-w-[60vh] max-w-[95%] w-full md:w-[50vw] hidden-scrollbar overflow-hidden overflow-y-auto rounded-lg px-1.5 md:top-0 mx-auto md:px-3">
          {editSection === "name" && (
            <EditName
              data={user}
              callback={() => {
                setEditSection("");
              }}
            />
          )}
        </div>
      </ModalContainer>

      <span className="fixed right-3 top-3 z-30">
        <Alert />
      </span>
    </div>
  );
};

export default AvatarCard;
