import React from "react";
import { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import ButtonSpinner from "../Loader/ButtonSpinner";

type Props = {
  label?: string;
  className?: string;
  loading?: boolean;
  onClick?: (e?: any) => void;
  type?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
};

const OutlineButton = ({ label, className, onClick, type, loading, disabled }: Props) => {
  const { theme } = useContext(ThemeContext);
  
  const onClickHandler = (e: any) => {
    if (typeof onClick === "function") {
      e.preventDefault();
      onClick && onClick();
    }
  };

  return (
    <button
      type={type}
      className={`${className} ${
        theme === "dark"
          ? "border-white text-white "
          : "border-primary text-primary "
      } flex items-center space-x-3 text-sm rounded-lg px-[30px] py-3.5 border font-semibold hover:cursor-pointer`}
      onClick={onClickHandler}
      disabled={disabled}
    >
      {label}
      {loading && <ButtonSpinner />}
    </button>
  );
};

export default OutlineButton;
