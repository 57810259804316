import { useState } from "react";
import { useForm } from "react-hook-form";
import useAlert from "src/hooks/use-alert";
import useStateHook from "src/hooks/useStateHook";
import { UserProfile } from "src/lib/endpoints/auth/auth.type";
import { useUpdateProfileMutation } from "src/lib/endpoints/profile/profile.api";
import Button from "../Button";
import OutlineButton from "../Button/OutlineButton";
import Heading from "./Heading";

enum FieldsName {
  FIRSTNAME = "firstname",
  LASTNAME = "lastname",
  PHONE = "phone",
}

interface InputFields {
  firstname: string;
  lastname: string;
  phone: string;
}

type Props = {
  data: UserProfile | null;
  callback: () => void;
};

const EditName = ({ data, callback }: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { state, open: openAlert, close: closeAlert, Alert } = useAlert();
  const { updateProfile } = useUpdateProfileMutation();
  const { handleStateUpdate } = useStateHook();
  const formMethods = useForm<InputFields>({
    defaultValues: {
      [FieldsName.FIRSTNAME]: data?.firstName ?? "",
      [FieldsName.LASTNAME]: data?.lastName ?? "",
      [FieldsName.PHONE]: data?.phone ?? "",
    },
  });

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors: formErrors },
  } = formMethods;

  const submit = handleSubmit(async (data: InputFields) => {
    if (isSubmitting) return;

    setIsSubmitting(true);
    const formData = new FormData();
    formData.append("firstname", data.firstname);
    formData.append("lastname", data.lastname);
    formData.append("phone", data.phone);

    updateProfile(formData, {
      onSuccess: (res) => {
        setIsSubmitting(false);
        openAlert({
          ...state,
          title: "Personal detail updated",
          variant: "success",
          active: true,
        });
        handleStateUpdate(res.data);
        callback && callback();
      },
      onError: (error: any) => {
        setIsSubmitting(false);
        openAlert({
          ...state,
          title: error?.response?.data?.message ?? error?.message ?? "",
          variant: "error",
          active: true,
        });
      },
    });
  });

  return (
    <div className="flex flex-col space-y-4 w-full">
      <Heading title="Personal Detail" />

      <section className="flex flex-col space-y-5 w-full">
        <section className="flex flex-col space-y-2 w-full">
          <section className="w-full flex flex-col space-y-1">
            <p className="text-sm font-medium text-gray-500">First Name</p>
            <span
              className={`w-full overflow-hidden rounded-lg border p-2 ${
                formErrors[FieldsName.FIRSTNAME]?.message && "border-red-600"
              }`}
            >
              <input
                id={FieldsName.FIRSTNAME}
                {...register(FieldsName.FIRSTNAME, {
                  required: "First name is required",
                })}
                placeholder="John"
                className="w-full"
              />
            </span>
            {formErrors[FieldsName.FIRSTNAME]?.message && (
              <p className="text-xs text-red-600">
                {formErrors[FieldsName.FIRSTNAME]?.message}
              </p>
            )}
          </section>

          <section className="w-full flex flex-col space-y-1">
            <p className="text-sm font-medium text-gray-500">Last Name</p>
            <span
              className={`w-full overflow-hidden rounded-lg border p-2 ${
                formErrors[FieldsName.LASTNAME]?.message && "border-red-600"
              }`}
            >
              <input
                id={FieldsName.LASTNAME}
                {...register(FieldsName.LASTNAME, {
                  required: "Last name is required",
                })}
                placeholder="Doe"
                className="w-full"
              />
            </span>
            {formErrors[FieldsName.LASTNAME]?.message && (
              <p className="text-xs text-red-600">
                {formErrors[FieldsName.LASTNAME]?.message}
              </p>
            )}
          </section>

          <section className="w-full flex flex-col space-y-1">
            <p className="text-sm font-medium text-gray-500">Phone number</p>
            <span
              className={`w-full overflow-hidden rounded-lg border p-2 ${
                formErrors[FieldsName.PHONE]?.message && "border-red-600"
              }`}
            >
              <input
                id={FieldsName.PHONE}
                {...register(FieldsName.PHONE, {
                  required: "Phone number is required",
                })}
                placeholder="08000000000"
                className="w-full"
              />
            </span>
            {formErrors[FieldsName.PHONE]?.message && (
              <p className="text-xs text-red-600">
                {formErrors[FieldsName.PHONE]?.message}
              </p>
            )}
          </section>
        </section>

        <div className="flex justify-between lg:justify-end lg:gap-6 items-center w-full pb-20">
          <OutlineButton
            label={"Cancel"}
            className={"border border-primary text-primary"}
            onClick={callback}
          />
          <Button
            type={"submit"}
            label={"Update"}
            loading={isSubmitting}
            className={"bg-primary text-white border border-transparent"}
            onClick={submit}
          />
        </div>
      </section>

      <span className="fixed right-3 top-3 z-30">
        <Alert />
      </span>
    </div>
  );
};

export default EditName;
