import React, { createContext, useState, useEffect } from 'react';

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const getInitialTheme = () => {
    // const storedTheme = localStorage.getItem('theme');
    // if (storedTheme) {
    //   return storedTheme;
    // }
    // return window.matchMedia('(prefers-color-scheme: dark)').matches
    //   ? 'dark'
    //   : 'light';

    return 'light'
  };

  const [theme, setTheme] = useState(getInitialTheme);

  // Use a parent container to apply the theme class instead of body
  // useEffect(() => {
  //   const appContainer = document.getElementById('app-container');
  //   if (appContainer) {
  //     appContainer.classList.add(theme === 'dark' ? 'dark' : 'light');
  //   }
  // }, [theme]);

  const toggleTheme = () => {
    // setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  // useEffect(() => {
  //   localStorage.setItem('theme', theme);
  // }, [theme]);

  // Add an event listener to handle potential changes in system color scheme
  // useEffect(() => {
  //   const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

  //   const handleChange = (e) => {
  //     setTheme(e.matches ? 'dark' : 'light');
  //   };

  //   mediaQuery.addEventListener('change', handleChange);
  //   return () => {
  //     mediaQuery.removeEventListener('change', handleChange);
  //   };
  // }, []);

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
