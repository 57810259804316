import { AiOutlineClose } from 'react-icons/ai';
import { FaFacebookSquare, FaLinkedin } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { IoLogoWhatsapp } from 'react-icons/io';
import useAlert from "src/hooks/use-alert";
import { selectUserState } from 'src/lib/features/auth/authSlice';
import { useTypedSelector } from 'src/lib/store/store';
import Logo from '../../assets/images/logo.png';
import { ReactComponent as Copy } from '../../assets/svgs/copy.svg';
import ControlledModal from './ControlledModal';

const ReferralModal = ({ open, closeModal }) => {
  const userState = useTypedSelector(selectUserState);
  const { state, open: openAlert, close, Alert } = useAlert();
  const closeModalHandler = () => {
    closeModal();
  };

  let contentStyle = {
    maxHeight: '90%',
    borderRadius: '12px',
    backgroundColor: 'white',
    width: 'fit-content',
    boxShadow: 'none',
    overflow: 'hidden',
    margin: 'auto',
    maxWidth: '530px',
  };

  const refUrl = userState?.user?.refLink ?? '';

  const message = `Droomwork is my new destination for freelance jobs. Join me on droomwork now to get verified and quality jobs\n\n${refUrl}\n\n`;

  const tags = "#droomwork #talent #client";

  const shareOnTwitter = () => {
    const hashtags = "droomwork,talents,client";
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      message
    )}&hashtags=${hashtags}`;
    window.open(twitterUrl, "_blank");
  };

  const shareOnWhatsapp = () => {
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(
      message + tags
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  const shareOnLinkedIn = () => {
    const linkedinShareUrl = `https://www.linkedin.com/sharing/share-offsite/?text=${encodeURIComponent(
      message + tags
    )}&url=${encodeURIComponent(refUrl)}`;
    window.open(linkedinShareUrl, "_blank");
  };

  const shareOnFacebook = () => {
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      refUrl + '\n\n' + message
    )}`;
    window.open(facebookShareUrl, "_blank", "noopener,noreferrer");
  };


  return (
    <ControlledModal
      open={open}
      closeModal={closeModalHandler}
      contentStyle={contentStyle}
    >
      <div className='relative text-center px-5 sm:px-11 pt-16 pb-24'>
        <div className='absolute right-4 top-4 hover:cursor-pointer'>
          <AiOutlineClose size={24} onClick={closeModal} fill='#575555' />
        </div>
        <div className='m-auto'>
          <div className='w-12 mb-5 m-auto'>
            <img src={Logo} alt='droomwork brand logo' />
          </div>
          <div>
            <p className='text-2xl text-primary mb-8 font-semibold'>
              Receive {parseFloat(process.env.REACT_APP_TALENT_REFERRAL_PERCENT) * 100}% of your referee's payment infow upon completion of each project milestone
            </p>
          </div>
          <div className='flex justify-between items-center p-2 sm:p-6 bg-[#D0D5DA] lg:gap-11'>
            <span className='text-xs lg:text-base text-primary truncate'>
              {userState.user.refLink}
            </span>
            <span onClick={() => {
              navigator.clipboard.writeText(userState.user.refLink ?? ''),
                openAlert({
                  ...state,
                  title: 'Copied',
                  variant: 'success',
                  active: true,
                })
            }}>
              <Copy />
            </span>
          </div>
        </div>

        <div className='flex justify-center items-center gap-7 mt-11'>
          <IoLogoWhatsapp
            className="w-8 h-8 text-green-600 hover:cursor-pointer"
            onClick={shareOnWhatsapp}
          />
          <FaLinkedin
            className="w-8 h-8 text-blue-600 hover:cursor-pointer"
            onClick={shareOnLinkedIn}
          />
          <FaSquareXTwitter
            className="w-8 h-8 hover:cursor-pointer text-black"
            onClick={shareOnTwitter}
          />
          <FaFacebookSquare
            className="w-8 h-8 text-blue-600 hover:cursor-pointer"
            onClick={shareOnFacebook}
          />
        </div>

        <span className="fixed right-3 top-3 z-30">
          <Alert />
        </span>
      </div>
    </ControlledModal>
  );
};

export default ReferralModal;
