import { Fragment, useEffect, useState } from "react";
import "./App.css";
import PageLoader from "./components/Loader/PageLoader";
import AllRoutes from "./config/routes";
import { ThemeProvider } from "./context/ThemeContext";
import useHandleLogout from "./hooks/useHandleLogout";
import {
  usePushNotificationMutation,
  useUserProfileMutation,
} from "./lib/endpoints/auth/auth.api";
import { addUserProfile, selectUserState } from "./lib/features/auth/authSlice";
import { useAppDispatch, useTypedSelector } from "./lib/store/store";
import CalendlyScheduleModal from "./components/Modal/CalendlySchedule";
import { generateToken, messaging } from "./lib/utils/firebase";
import { onMessage } from "firebase/messaging";
import GoogleAnalytics from "./components/GoogleAnalytics";
import HotJar from "./components/Hotjar";
import { hotjar } from "react-hotjar";
import { GOOGLE_ANALYTICS, HOTJAR_ID } from "./utils/constants";
import ReactGA from "react-ga4";

import CompleteProfileUpdate from "./components/Modal/CompleteProfileUpdate";

export const TOKEN = "droomwork-talent-token";

function App() {
  const userState = useTypedSelector(selectUserState);
  const dispatch = useAppDispatch();
  const { getProfile } = useUserProfileMutation();
  const { subscribe } = usePushNotificationMutation();
  const { handleLogout } = useHandleLogout();
  const [modalProfileUpdate, setModalProfileUpdate] = useState(true);
  const [modalInterview, setModalInterview] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  // console.log("userState", userState);
  // eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOjEsImlhdCI6MTcxNTk3NzExNn0.AjRrQRbdB8ZHoksRjN5IwldUpPYpxv8pj8p03CqhhIs
  // eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOjMzLCJpYXQiOjE3MzQ5MTA2NDJ9.LmYh0t9v9OG8HNd9STJ7QdKQW0y74fodg3R8SXlqEn4

  const handlePushNotificationToken = async () => {
    const token = await generateToken();

    if (!token) return;

    const formData = new FormData();
    formData.append("push_token", token);

    try {
      !isSubscribed &&
        subscribe(formData, {
          onSuccess: (res) => {
            setIsSubscribed(true);
            // console.log('response ', res)
          },
          onError: (error: any) => {
            console.log("error ", error);
          },
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (process.env.NODE_ENV === "developement") return;
    hotjar.initialize({ id: HOTJAR_ID, sv: 6 });
    ReactGA.initialize(GOOGLE_ANALYTICS);
    ReactGA.send({ hitType: "pageview", page: window?.location?.pathname });
  }, []);

  useEffect(() => {
    if (isMounted && !!userState.accessToken) {
      handlePushNotificationToken();
      onMessage(messaging, (payload) => {
        new Notification(payload.notification?.title ?? "", {
          icon: payload.notification?.icon ?? "./logo.png",
          body: payload.notification?.body ?? "",
        });
      });
    } else {
      setIsMounted(true);
    }
  }, [isMounted, userState]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    try {
      if (params.has("token")) {
        const formData = new FormData();
        const token = params.get("token")?.trim() ?? "";
        formData.append("userToken", token);
        getProfile(formData, {
          onSuccess: (res) => {
            const userInfo = {
              accessToken: res.accessToken,
              user: {
                ...res.user.data[0],
                extraDetail: res.user.data[1],
              },
            };
            dispatch(addUserProfile(userInfo));
          },
          onError: (error: any) => {
            // console.log("error", error);
          },
        });
        localStorage.setItem(TOKEN, token ?? "");
      } else if (localStorage.getItem(TOKEN)) {
        if (userState.accessToken) return;

        const formData = new FormData();
        formData.append("userToken", localStorage.getItem(TOKEN) ?? "");
        getProfile(formData, {
          onSuccess: (res) => {
            const userInfo = {
              accessToken: res.accessToken,
              user: {
                ...res.user.data[0],
                extraDetail: res.user.data[1],
              },
            };
            dispatch(addUserProfile(userInfo));
          },
          onError: (error: any) => {
            // console.log("error", error);
          },
        });
      } else {
        handleLogout();
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  if (userState.accessToken === null) return <PageLoader />;
  if (userState?.user?.role !== "developer")
    return (
      <Fragment>
        <PageLoader />
      </Fragment>
    );
    
  return (
    <>
      <GoogleAnalytics />
      <HotJar />

      <ThemeProvider>
        <div className="relative">
          <AllRoutes />
          {(!userState.user.cv_public_id || !userState.user.photo_public_id) &&
            modalProfileUpdate && (
              <CompleteProfileUpdate
                close={() => {
                  setModalProfileUpdate(false);
                  setModalInterview(true);
                }}
                open
              />
            )}
          {/* {!userState.user.is_interviewed && modalInterview && (
            <CalendlyScheduleModal
              close={() => setModalInterview(false)}
              open
            />
          )} */}
        </div>
      </ThemeProvider>
    </>
  );
}

export default App;
